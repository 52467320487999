import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { Box, Button, Divider } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useStore } from '../../../contexts/store'
import Prompt from "../../Elements/Prompt"
import { PodClass } from "../../../classes/Pod"
import { Op_editPod } from "../../../shared/src/types/Ops"
import { downloadableType } from "../../../shared/src/types/Miscs"
import dayjs from "dayjs"
import { ImageUploadbutton } from "../../Elements/ImageUploadButton"
import ColorSelect from "../../Elements/ColorSelect"
import { podNameMaxLength } from "../../../shared/src/validation/validationConstantsString"


// message from thread
const Appearance = () => {
  const { podStore, opStore, sessionStore } = useStore()
  const [showPodNamePrompt, setShowPodNamePrompt] = useState<boolean>(false)
  const [showPodDescriptionPrompt, setShowPodDescriptionPrompt] = useState<boolean>(false)

  const { t } = useTranslation()
  const session = sessionStore.session

  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const changePodSetting = (which:'name'|'description'|'keyphrase', s:string) => {
    if (s !== null || s !== undefined) {
      const mods: {
        name?: string,
        description?: string,
        allowDownload?:downloadableType[],
        keyphrase?: string,
        tModified?: number
      } = {
        tModified: dayjs().unix()
      }
      mods[which] = s

      const op:Op_editPod = {
        op: 'editPod',
        podId: pod.podId,
        data: {
          mods,
          userId: session.user.userId,
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          userName: podStore.userPseudonym || '',
        }
      }

      opStore.doOp(op)
    }
  }

  const op:Op_editPod = {
    op: 'editPod',
    podId: pod.podId,
    data: {
      mods: {  },
      userId: session.user.userId,
      usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
      userName: podStore.userPseudonym || '',
    }
  }

  const podImageUploadSuccess = (fileId:string) => {
    op.data.mods.podImageFileId = fileId
    console.log(op)
    opStore.doOp(op)
  }

  const podImageUploadFailure = () => {
    console.log('failure')
  }

  const setColor = (newColor:string)=> {
    const op:Op_editPod = {
      op: 'editPod',
      podId: pod.podId,
      data: {
        mods: { podColor: newColor },
        userId: session.user.userId,
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userName: podStore.userPseudonym || '',
      }
    }
    opStore.doOp(op)
  }


  return <Box>
          <Box sx={{mt:1, mb:1}}>
            <Button variant='outlined' disabled={!pod.permissions['editPod']} onClick={() => {setShowPodNamePrompt(true)}}>{t('Change Pod Name')}</Button>&nbsp;
          </Box>
          <Divider />
          <Box sx={{mt:1, mb:1}}>
            <Button variant='outlined' disabled={!pod.permissions['editPod']} onClick={() => {setShowPodDescriptionPrompt(true)}}>{t('Change Pod Description')}</Button>&nbsp;
          </Box>
          <Divider />
          <Box sx={{mt:1, mb:1, display:'flex', alignItems:'center'}}>
            <ColorSelect disabled={!pod.permissions['editPod']} color={pod.podColor ? pod.podColor : 'eee'} setColor={setColor} sx={{marginRight:1}} />
            <ImageUploadbutton
              disabled={!pod.permissions['editPod']}
              type="podImage"
              fileId={pod.podImageFileId}
              success={podImageUploadSuccess}
              failure={podImageUploadFailure}
              width={1280}
              height={1280}
              variant='outlined'
            />
            {
              pod.podImageFileId && <Button
                variant="outlined"
                disabled={!pod.permissions['editPod']}
                sx={{marginLeft:1}}
                onClick={() => {
                const op:Op_editPod = {
                  op: 'editPod',
                  podId: pod.podId,
                  data: {
                    userId: session.user.userId,
                    usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
                    userName: podStore.userPseudonym || '',
                    mods: {
                      podImageFileId:null
                    }
                  }
                }
                opStore.doOp(op)
              }}>{t('Remove Image')}</Button>
            }
          </Box>
          <Prompt title={t('Pod Name')} info={t('Please enter a new name for this pod') as string} open={showPodNamePrompt} handleClose={() => { setShowPodNamePrompt(false) }} onOk={(s:string) => { changePodSetting('name', s) }} type={{type:'string', minLength: 1, maxLength: podNameMaxLength}} defaultValue={pod.name} />
          <Prompt title={t('Pod Description')} info={t('Please enter a description for this pod') as string} open={showPodDescriptionPrompt} handleClose={() => { setShowPodDescriptionPrompt(false) }} onOk={(s:string) => { changePodSetting('description', s) }} type={{type:'string', maxLength:255}} defaultValue={pod.description} />
        </Box>
}

export default observer(Appearance)

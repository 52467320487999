import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useParams } from "../../../helper/Helper"
import { useStore } from "../../../contexts/store"
import { PodClass } from "../../../classes/Pod"
import { iComment, interactionAnchor } from "../../../shared/src/types/Interaction"
import { Op_addComment, Op_deleteAnnotation, Op_editComment } from "../../../shared/src/types/Ops"
import TextArea from "../../Elements/TextArea"
import { Box } from "@mui/material"

const InteractionModalComment  = ({
    anchor,
    interaction
  }:
  {
    anchor: interactionAnchor | null,
    interaction: iComment
  }) => {
  const { t } = useTranslation()
  const [ label, setLabel ] = useState<string>("")
  const { podId, pdfId } = useParams()
  const { sessionStore, podStore, opStore, uiStore } = useStore()
  const { session } = sessionStore
  const isTransformation = (interaction.interactionType === "annotation") ? true : false

  useEffect(() => {
    // if comment is edited: load existing comment data
    if(interaction && !isTransformation) {
      setLabel(interaction.label)
    }
  }, [podStore, interaction, pdfId, isTransformation])

  if(anchor === null) return null
  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const submitEdit = (input: string) => {
    const editAnchor = podStore.getInteractionEditAnchor(interaction.interactionId)
    // distinguish whether comment is edited or newly transformed
    if(!isTransformation) {
      // save only if something changes in the input
      if(label!== input || editAnchor) {
        const op:Op_editComment = {
          op: "editComment",
          podId: podId,
          data: {
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
            userId: sessionStore.session.user.userId,
            userName: podStore.userPseudonym || '',
            interactionId: interaction.interactionId,
            mods: {
              anchor: (editAnchor) ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(anchor)),
              label: input,
              tModified: dayjs().unix()
            }
          }
        }
        opStore.doOp(op)
      }
    } else {
      // transformation: add comment, delete annotation
      const transformationAnchor = {
        nodeId: anchor.nodeId,
        relText: anchor.relText,
        rects: JSON.parse(JSON.stringify(anchor.rects)),
        tool: anchor.tool
      }
      const op:Op_addComment = {
        op: "addComment",
        podId: podId,
        data: {
          interactionId: sessionStore.createUuid(),
          interactionType: 'comment',
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
          userId: session.user.userId,
          userName: podStore.userPseudonym,
          anchor: editAnchor ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(transformationAnchor)),
          style: { color: null },
          label: input,
          reactions: {},
          tCreated: dayjs().unix(),
          tModified: dayjs().unix(),
          coid:null,
          tSeen: dayjs().unix(),
          dSeen: 1
        }
      }
      opStore.doOp(op)
      // delete annotation
      opStore.doOp({
        op: 'deleteAnnotation',
        podId,
        data: {
          userId: sessionStore.session.user.userId,
          userName: podStore.userPseudonym as string,
          usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
          interactionId: interaction.interactionId
        }
      } as Op_deleteAnnotation)
    }
    handleClose()
  }

  function handleClose() {
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
    // remove edited text selection
    if(interaction) podStore.deleteInteractionEditAnchor(interaction.interactionId)
  }

  return (
    <Box sx={{ display: "grid" }}>
      <Box sx={{ maxWidth: "600px", justifySelf: "center", width: "100%" }}>
        <TextArea
          handleClose={handleClose}
          placeholder={t("comment")}
          showHints={false}
          submit={submitEdit}
          value={label}
          disabled={interaction ? !(pod.isAllowed('editComment', interaction.interactionId)) : !(pod.isAllowed('addComment') && pod.isAllowed('addThread'))}
        />
      </Box>
    </Box>
  )
}

export default InteractionModalComment
export const environment:{[key: string]: any} = {
  //backendBaseUrl: 'http://localhost:5000',
  backendBaseUrl: 'https://backend_staging.shrimpp.de',
  dev: true,
  idps: [{
    name: 'keycloak',
    loginEndpoint: 'login',
    description: '',
  }, {
    name: 'satosa',
    loginEndpoint: 'login_satosa',
    description: '',
  },
  {
    name: 'local',
    loginEndpoint: '*local',
    description: '',
  }]
}
import { observer } from "mobx-react-lite"
import { interactionAnchor } from "../../../shared/src/types/Interaction"
import { useStore } from "../../../contexts/store"
import Blockquote from "../../Elements/Blockquote"

const EditInteractionBlockquote = ({anchor, color, interactionId}: {anchor: interactionAnchor | null, color: string, interactionId: string | null}) => {
  const { podStore } = useStore()
  let relText = ""
  if(anchor && anchor.relText) {
    relText = anchor.relText
  }
  // if available, show temporary marking in text
  if(interactionId) {
    const editAnchor = podStore.getInteractionEditAnchor(interactionId)
    if(editAnchor && editAnchor.relText) relText = editAnchor.relText
  }

  return (
    <Blockquote color={color} hasToggleButton={false} text={relText} />
  )
}

export default observer(EditInteractionBlockquote)
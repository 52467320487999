import { t } from "i18next"
import dayjs from "dayjs"
import { observer } from "mobx-react-lite"
import { ChangeEvent, useEffect, useState } from "react"
import { useStore } from "../../../contexts/store"
import { useParams } from "../../../helper/Helper"
import { iAnnotation, interactionAnchor } from "../../../shared/src/types/Interaction"
import { Op_editAnnotation } from "../../../shared/src/types/Ops"
import SaveCancel from "../../Elements/SaveCancel"
import { Alert, Box, TextField } from "@mui/material"
import ColorPicker from "../../Elements/ColorPicker"

const InteractionModalAnnotation  = ({
  anchor,
  interaction
}:
{
  anchor: interactionAnchor | null,
  interaction: iAnnotation
}) => {
  const { podId } = useParams()
  const { podStore, uiStore, opStore, sessionStore } = useStore()
  const { session } = sessionStore
  const [label, setLabel] = useState<string>("")
  const [hasNote, setHasNote] = useState(true)
  const [color, setColor] = useState<string>("")

  useEffect(() => {
    // if annotation is edited: load existing annotation data
    if(interaction) {
      setLabel(interaction.label)
      if(interaction.style.color) setColor(interaction.style.color)
    }

    return () => {
      // remove color preview
      uiStore.deletePreviewColor()
    }
  }, [podStore, interaction, uiStore])

  if(anchor === null) return null

  const handleSave = () => {
    if(interaction && label) {
      const editAnchor = podStore.getInteractionEditAnchor(interaction.interactionId)
      // save only if something changes
      if( editAnchor || label !== interaction.label || color !== interaction.style.color) {
        opStore.doOp({
          op: "editAnnotation",
          podId: podId,
          data: {
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
            userId: session.user.userId,
            userName: podStore.userPseudonym,
            interactionId: interaction.interactionId,
            mods: {
              anchor: (editAnchor) ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(anchor)),
              label: label,
              style: {
                color: color,
              },
              tModified: dayjs().unix()
            }
          }
        } as Op_editAnnotation)
      }
      // next annotation will use newly selected color
      if(color !== interaction.style.color) uiStore.setAnnotationColor(color)
      handleClose()
    } else {
      setHasNote(false)
    }
  }

  function handleClose() {
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
    // remove edited text selection
    if(interaction) podStore.deleteInteractionEditAnchor(interaction.interactionId)
    // remove color preview
    uiStore.deletePreviewColor()
  }

  function pickedColor(color: string) {
    // used if annotation is saved
    setColor(color)
    // show color change in component
    uiStore.setPreviewColor(interaction.interactionId, color)
  }

  return (
    <Box sx={{display: "grid", gridTemplateRows: "max-content auto",  alignItems: "end"}}>
      <Box  sx={{ display: "grid", margin: "5px 10px", maxWidth: "600px", justifySelf: "center", width: "100%"}}>
        {/* color picker */}
        <Box sx={{display: "grid", margin: "20px 0px"}}>
          <ColorPicker color={color} setColor={(color) => pickedColor(color)} />
        </Box>
        {/* notes */}
        <TextField
          sx={{backgroundColor:"#dedefd"}}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {setLabel(e.target.value); setHasNote(true)}}
          label={t("Notes")}
          minRows={3}
          maxRows={3}
          multiline
          value={label}
          variant="outlined"
          onKeyDown={(ev) => {
            if(ev.key === 'Enter') {
              handleSave()
              ev.preventDefault()
            }
          }}
        />
        {!hasNote &&
          <Alert sx={{justifyContent: "center", marginTop: "3px"}} severity="warning">{t('Add')} {t('Notes')}</Alert>
        }
      </Box>
      <Box sx={{ maxWidth: "600px", justifySelf: "center", width: "100%", marginRight: "-20px" }}>
        <SaveCancel handleSave={handleSave} handleClose={handleClose} />
      </Box>
    </Box>
  )
}

export default observer(InteractionModalAnnotation)
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { navigate } from "wouter/use-location"
import { useStore } from '../contexts/store'
import { PodClass } from '../classes/Pod'

import { Button, Alert, AlertTitle, TextField, Box, FormGroup, FormControlLabel, Switch, CircularProgress, Stack } from '@mui/material'

import Loading from './Loading'
import LoadingPod from './Pod/LoadingPod'
import Infobox from './Infobox'
import EditUserInfo from './Pod/EditUserInfo'

import { useTranslation } from 'react-i18next'
import { Op_addUserToPod } from '../shared/src/types/Ops'
import { UserInfo } from '../shared/src/types/User'
import api from '../api/api'
import { PodInformation } from '../shared/src/types/Pod'
import UserInfoAvatar from './Elements/UserInfoAvatar'
import { podKeyphraseMinLength, podKeyphraseMaxLength, uuidMaxLength, notUuidRegex  } from "../shared/src/validation/validationConstantsString"

interface PodProps {
  podId: string
}

const PodProvider = (props: React.PropsWithChildren<PodProps>)  => {
  const { podId } = props
  const { t } = useTranslation()
  const { podStore, opStore, sessionStore } = useStore()
  const pod:PodClass|null = podStore.pod
  const [ showJoinDialog, setShowJoinDialog ] = useState<UserInfo|null>(null)
  const [ podInfo, setPodInfo ] = useState<null|'loading'|'failed'|PodInformation>(null)
  const [ keyphrase, setKeyphrase ] = useState<string>('')
  const [ hasNoKeyphrase, setHasNoKeyphrase ] = useState(false)

  const getPodInfo = async(podId:string) => {
    if (podInfo !== null) return
    setPodInfo('loading')
    const res = await api.getPodInformation(podId)
    if (res) {
      setPodInfo(res)
    }
    else {
      setPodInfo('failed')
    }
  }

  // validate podId @todo: perhaps move to a central function (in shared?) and use this in the podStore directly to deny invalid attempts at loading a pod
  const podIdIsValid = (input:string) => {
    if (input.length > uuidMaxLength) return false
    if (String(input).match(notUuidRegex)) return false
    return true
  }

  useEffect(() => {
    if ((pod === null) || (pod.podId !== podId) || (pod.status === 'initialized')) {
      if (podIdIsValid(podId)) podStore.loadPod(podId)
    }
    if (pod && pod.status === 'unknown') {
      getPodInfo(podId)
    }
  })

  // Inform the user if the podId is formally invalid
  if (!podIdIsValid(podId)) {
    return <Infobox>Invalid podId "{podId}"</Infobox>
  }

  const issueJoinPod = (userInfo:UserInfo|undefined) => {
    setShowJoinDialog(null)
    if (userInfo) {
      const { userName, color} = userInfo
      if (userName.trim()) {
        const op:Op_addUserToPod = {
          op: 'addUserToPod',
          podId,
          data: {
            podName: 'Unknown Pod',
            createPod: false,
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId, // has to be 'private' because we cannot yet determine the pod usergroup of the pod
            userId: sessionStore.session.user.userId,
            login: sessionStore.session.user.login,
            userName: userName.trim(),
            color: color,
            type: 'request',
            keyphrase: forceValidKeyphrase(keyphrase)
          }
        }
        console.log(op)
        opStore.doOp(op)
      }
    }
  }

  const title = t('Select a Name/Color to Join')

  // no data available
  if (pod === null) return <Infobox>The 'pod' object is null when trying to show pod {podId}</Infobox>

  // wait until podId has been updated in store
  if (pod.podId !== podId) return <Loading info="Waiting for Pod" />

  // pod is loading
  if (pod.status === "loading") return <LoadingPod pod={pod} />

  if (pod.status === 'broken') return <Infobox>
    <Alert severity="error">
      <AlertTitle>{t('Pod Unavailable')}</AlertTitle>
      {t('This pod could not be loaded')}
      </Alert>
      <div style={{textAlign:'center', paddingTop:'1em'}}>
      <Button variant='outlined' onClick={async () => { await podStore.resetPod(pod.podId) }}>Reset</Button>
    </div>
  </Infobox>

  if (pod.status === 'deleted') return <Infobox>
    <Alert severity="error">
      <AlertTitle>{t('Deleted Pod')}</AlertTitle>
      {t('This pod has been deleted. It is no longer available.')}
      </Alert>
      <div style={{textAlign:'center', paddingTop:'1em'}}>
      <Button variant='outlined' onClick={ () => navigate('/') }>Ok</Button>
    </div>
  </Infobox>

  const keyphraseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var newValue = event.target.value.trim()
    newValue = newValue.substring(0, podKeyphraseMaxLength)
    setKeyphrase(newValue)
  }

  // as long as it is not clear whether the pod has a password,
  // an empty password field is equated with no password.
  const isValidKeyphrase = (keyphrase: string) => {
    if(hasNoKeyphrase) return true
    if (podInfo && typeof podInfo === 'object' && !podInfo.hasKeyphrase) return true
    if(keyphrase.length < podKeyphraseMinLength) return false
    if(keyphrase.length > podKeyphraseMaxLength) return false
    return true
  }

  // return null unless we affirmatively know that the user wants to / should send a keyphrase and the keyphrase is valid
  const forceValidKeyphrase = (keyphrase:string) => {
    if (podInfo && typeof podInfo === 'object' && !podInfo.hasKeyphrase) return null
    if (hasNoKeyphrase) return null
    if(keyphrase.length < podKeyphraseMinLength) return null
    if(keyphrase.length > podKeyphraseMaxLength) return null
    return keyphrase
  }

  if (pod.status === "unknown") {

    if (podInfo && typeof podInfo === 'object') {
      const headerStyles:React.CSSProperties = {
        height: podInfo.podColor ? 80 : 0,
        display:'flex',
        alignItems: 'center',
        overflow: 'hidden',
        minWidth: '400px',
      }

      if (podInfo.podColor) {
        headerStyles.backgroundColor = podInfo.podColor
      }

      return <Infobox>
        <Alert severity="info">
          <AlertTitle>{t('Joining Pod')}</AlertTitle>
        </Alert>
        <Box style={headerStyles}></Box>
        <Box style={{paddingTop:20}}><strong>{podInfo.name}</strong></Box>
        <Box style={{paddingTop:20}}>{podInfo.description}</Box>
        <Stack direction="row" spacing={2} sx={{mt:1, mb: 2, justifyContent: "space-between", }}>
          <UserInfoAvatar userId={podInfo.creator?.userId || 0} podId={pod.podId} sx={{height:24, width:24, fontSize:'80%'}} />
          <Box style={{flexGrow:2}}>{podInfo.creator?.userName}</Box>
        </Stack>
        <div style={{textAlign:'center'}}>
        { !podInfo.hasKeyphrase ?
          null :
          <Box style={{paddingTop:20}}>
            <TextField
            label={t('Keyphrase')}
            helperText={t('Your instructor has set a keyphrase for this pod. Please enter it here to join. The keyphrase must be at least {{minLength}} and no more than {{maxLength}} characters long.', { minLength: podKeyphraseMinLength, maxLength: podKeyphraseMaxLength })}
            value={keyphrase}
            onChange={keyphraseChange}
            />
          </Box>}
          <Box style={{paddingTop:20, textAlign:'right'}}>
            <Button variant="outlined" sx={{mr:1}} onClick={() => { navigate('/') }}>{t('Cancel')}</Button>
            <Button disabled={!isValidKeyphrase(keyphrase)} variant='contained' onClick={() => {setShowJoinDialog({userId: sessionStore.session.user.userId, login: sessionStore.session.user.login, userName:sessionStore.session.user.idpProvidedUserName || '', color:'#aaa'})}}>{t('Join')}</Button>
          </Box>
          <EditUserInfo title={title} userInfo={showJoinDialog} handleClose={issueJoinPod} />
        </div>
      </Infobox>
    }
    else {
      return <Infobox>
        <Alert severity="warning">
          <AlertTitle>{t('Unknown Pod')}</AlertTitle>
          {t('The pod status is unknown, so it either does not exist or you do not have sufficient privileges to view it.')}
        </Alert>
        <div style={{textAlign:'center', paddingTop:'1em'}}>
          <Box style={{marginBottom:20}}>
            <FormGroup sx={{display: "grid", gridTemplateColumns: "max-content"}}>
              <FormControlLabel control={<Switch checked={hasNoKeyphrase} onChange={() => {setHasNoKeyphrase(!hasNoKeyphrase)}} />} label={t('Pod is not password protected') as string} />
            </FormGroup>
            <TextField
                disabled={hasNoKeyphrase}
                helperText={t('You may have received a keyphrase to join this pod. If so, please enter it here. The keyphrase must be at least {{minLength}} and no more than {{maxLength}} characters long.', { minLength: podKeyphraseMinLength, maxLength: podKeyphraseMaxLength })}
                label={t('Keyphrase')}
                onChange={keyphraseChange}
                sx={{opacity: hasNoKeyphrase ? 0.4 : 1}}
                value={keyphrase}
              />
          </Box>
          <Box>
            <Button variant="outlined" sx={{mr:1}} onClick={() => { navigate('/') }}>{t('Cancel')}</Button>
            <Button disabled={!isValidKeyphrase(keyphrase)} variant='contained' onClick={() => {setShowJoinDialog({userId: sessionStore.session.user.userId, login: sessionStore.session.user.login, userName:sessionStore.session.user.idpProvidedUserName || '', color:'#aaa'})}}>Click to join (if it exists at all)</Button>
          </Box>
          <EditUserInfo title={title} userInfo={showJoinDialog} handleClose={issueJoinPod} />
        </div>
      </Infobox>
    }
  }

  if (pod.status === "requested") return <Infobox>
    <Alert severity="info">
      <AlertTitle>{t('Access Pending')}</AlertTitle>
      {t('You have requested access to this pod, which will be checked once you are back online and have synced all your pending operations.')}
    </Alert>
  </Infobox>

  if (pod.status !== 'loaded') return <Infobox>Pod is {pod.status}</Infobox>

  return <>{props.children}</>
}

export default observer(PodProvider)
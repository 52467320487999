import { ChangeEvent, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import dayjs, { Dayjs } from "dayjs"
import { useParams } from "../../../helper/Helper"
import { useStore } from "../../../contexts/store"
import { iReadingQuestion, interactionAnchor } from "../../../shared/src/types/Interaction"
import { Op_addReadingQuestion, Op_addThread, Op_deleteAnnotation, Op_editReadingQuestion } from "../../../shared/src/types/Ops"
import { Thread as ThreadType } from "../../../shared/src/types/Message"
import SaveCancel from "../../Elements/SaveCancel"
import { Alert, Box, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, TextField } from "@mui/material"
import { DateTimePicker } from '@mui/x-date-pickers'

const InteractionModalReadingQuestion  = ({
    anchor,
    interaction
  }:
  {
    anchor: interactionAnchor | null,
    interaction: iReadingQuestion
  }) => {
  const { t } = useTranslation()
  const [label, setLabel] = useState<string>("")
  const [openUntil, setOpenUntil] = useState<Dayjs | null>(null)
  const [showOpenUntilPicker, setShowOpenUntilPicker] = useState(false)
  const [answerVisibility, setAnswerVisibility] = useState("public")
  const [answerVisibilityDelay, setAnswerVisibilityDelay] = useState<Dayjs | null>(null)
  const [showAnswerVisibilityDelayPicker, setShowAnswerVisibilityDelayPicker] = useState(false)
  const [isValidReadingQuestion, setIsValidReadingQuestion] = useState(true)
  const { podId, pdfId } = useParams()
  const { sessionStore, podStore, opStore, uiStore } = useStore()
  const { session } = sessionStore
  const isTransformation = (interaction.interactionType === "annotation") ? true : false
  // ref date picker error
  let openUntilError = useRef<string | null>(null)
  let answerVisibilityDelayError = useRef<string | null>(null)

  useEffect(() => {
    // if readingQuestion is edited: load existing readingQuestion data
    if(interaction && !isTransformation) {
      setLabel(interaction.label)
      setAnswerVisibility(interaction.answerVisibility)
      if(interaction.openUntil) {
        setOpenUntil(dayjs.unix(interaction.openUntil))
        setShowOpenUntilPicker(true)
      }
      if(interaction.answerVisibilityDelay) {
        setAnswerVisibilityDelay(dayjs.unix(interaction.answerVisibilityDelay))
        setShowAnswerVisibilityDelayPicker(true)
      }
    }
  }, [podStore, interaction, pdfId, isTransformation])

  if(anchor === null) return null

  const handleSave = () => {
    if(label === "" || !label) {
      setIsValidReadingQuestion(false)
    } else if( (showOpenUntilPicker && (!openUntil || openUntilError.current !== null) ) ||
               (showAnswerVisibilityDelayPicker && (!answerVisibilityDelay || answerVisibilityDelayError.current !== null) ) ) {
      setIsValidReadingQuestion(false)
    }
    else {
      // transform dayjs into unixtime
      const openUntilUnixtime = openUntil ? openUntil.unix() : null
      const answerVisibilityDelayUnixtime = answerVisibilityDelay ? answerVisibilityDelay.unix() : null
      const editAnchor = podStore.getInteractionEditAnchor(interaction.interactionId)
      // distinguish whether readingQuestion is edited or newly created
      if(!isTransformation) {
        // save only if something changes in the input or relText was edited
        if(label !== interaction.label || openUntil !== interaction.openUntil || answerVisibility !== interaction.answerVisibility || answerVisibilityDelay !== interaction.answerVisibilityDelay || editAnchor) {
          opStore.doOp({
            op: "editReadingQuestion",
            podId: podId,
            data: {
              interactionType: 'readingQuestion',
              usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
              userId: session.user.userId,
              userName: podStore.userPseudonym,
              interactionId: interaction.interactionId,
              mods: {
                anchor: (editAnchor) ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(anchor)),
                label: label,
                tModified: dayjs().unix(),
                openUntil: openUntilUnixtime,
                answerVisibility: answerVisibility,
                answerVisibilityDelay: answerVisibilityDelayUnixtime
              }
            }
          } as Op_editReadingQuestion)
        }
      } else {
        // transformation: add readingQuestion, delete annotation
        const transformationAnchor = {
          nodeId: anchor.nodeId,
          relText: anchor.relText,
          rects: JSON.parse(JSON.stringify(anchor.rects)),
          tool: anchor.tool
        }
        const interactionId = sessionStore.createUuid()
        opStore.doOp({
          op: "addReadingQuestion",
          podId: podId,
          data: {
            interactionType: 'readingQuestion',
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
            interactionId: interactionId,
            userId: session.user.userId,
            userName: podStore.userPseudonym,
            anchor: editAnchor ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(transformationAnchor)),
            style: { color: null },
            label: label,
            reactions: {},
            tCreated: dayjs().unix(),
            tModified: dayjs().unix(),
            coid: null,
            openUntil: openUntilUnixtime,
            answerVisibility: answerVisibility,
            answerVisibilityDelay: answerVisibilityDelayUnixtime,
            tSeen: dayjs().unix(),
            dSeen: 1
          }
        } as Op_addReadingQuestion)
        // create new thread for reading question
        const threadId = `T.${interactionId}` // base the threadId on the interactionId
        opStore.doOp({
          op: "addThread",
          podId: podId,
          data: {
            coid: null,
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
            interactionId: interactionId,
            messages: [],
            threadName: "",
            tCreated: dayjs().unix(),
            tModified: dayjs().unix(),
            threadId: threadId,
            userId: session.user.userId,
            userName: podStore.userPseudonym,
          } as ThreadType
        } as Op_addThread)
        // delete annotation
        opStore.doOp({
          op: 'deleteAnnotation',
          podId,
          data: {
            userId: sessionStore.session.user.userId,
            userName: podStore.userPseudonym as string,
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
            interactionId: interaction.interactionId
          }
        } as Op_deleteAnnotation)
      }
      handleClose()
    }
  }

  const handleClose = () => {
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
    // remove edited text selection
    if(interaction) podStore.deleteInteractionEditAnchor(interaction.interactionId)
  }

  const changeOpenUntilSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOpenUntilPicker(event.target.checked)
    // clear date and time picker
    setOpenUntil(null)
  }

  const changeAnswerVisibilityDelaySwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAnswerVisibilityDelayPicker(event.target.checked)
    // clear date and time picker
    setAnswerVisibilityDelay(null)
  }

  const handleOpenUntilChange = (newDate: Dayjs | null) => {
    setOpenUntil(newDate)
  }

  const handleAnswerVisibilityDelayChange = (newDate: Dayjs | null) => {
    setAnswerVisibilityDelay(newDate)
  }

  const handleAnswerVisibilityChange = (event: SelectChangeEvent) => {
    setAnswerVisibility(event.target.value as string)
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value)
    if(!isValidReadingQuestion) setIsValidReadingQuestion(true)
  }

  const AnswerVisibility = () => (
    <FormControl sx={{ margin: "10px 0" }} fullWidth>
      <InputLabel id="answer-visibility-label">{t("Answer visibility")}</InputLabel>
      <Select
        labelId="answer-visibility-label"
        value={answerVisibility}
        label={t('Answer visibility')}
        onChange={handleAnswerVisibilityChange}
      >
        <MenuItem value={"public"}>{t('public')}</MenuItem>
        <MenuItem value={"instructor"}>{t('instructor')}</MenuItem>
        <MenuItem value={"afterReply"}>{t('afterReply')}</MenuItem>
      </Select>
    </FormControl>
  )

  return (
    <Box sx={{display: "grid", gridTemplateRows: "max-content max-content auto", alignItems: "end"}}>
      <Box  sx={{ display: "grid", gridTemplateColumns: "auto", margin: "5px 10px 15px 10px", maxWidth: "600px", justifySelf: "center", width: "100%"}}>
        <AnswerVisibility />
        {/* switch and date picker for openUntil */}
        <FormGroup sx={{marginBottom: "5px", padding: "0 5px"}}>
          <FormControlLabel label={t('Set date and time for deadline')} control={
            <Switch checked={showOpenUntilPicker} onChange={changeOpenUntilSwitch} />
          } />
        </FormGroup>
        {showOpenUntilPicker &&
          <DateTimePicker
            closeOnSelect={false}
            disablePast
            maxDate={dayjs('2038-01-01')}
            onAccept={() => {openUntilError.current = null; setIsValidReadingQuestion(true)}}
            onChange={handleOpenUntilChange}
            onError={(err) => openUntilError.current = err}
            value={openUntil}
          />
        }
        {/* switch and date picker for answerVisibilityDelay */}
        <FormGroup sx={{marginBottom: "5px", padding: "0 5px"}}>
          <FormControlLabel label={t('Time from which the answers are visible')} control={
            <Switch checked={showAnswerVisibilityDelayPicker} onChange={changeAnswerVisibilityDelaySwitch} />
          } />
        </FormGroup>
        {showAnswerVisibilityDelayPicker &&
          <DateTimePicker
            closeOnSelect={false}
            disablePast
            maxDate={dayjs('2038-01-01')}
            onAccept={() => {answerVisibilityDelayError.current = null; setIsValidReadingQuestion(true)}}
            onChange={handleAnswerVisibilityDelayChange}
            onError={(err) => answerVisibilityDelayError.current = err}
            value={answerVisibilityDelay}
          />
        }
      </Box>
      <Box  sx={{ display: "grid", margin: "5px 10px", maxWidth: "600px", justifySelf: "center", width: "100%"}}>
        <TextField
          sx={{backgroundColor:"#dedefd"}}
          minRows={3}
          maxRows={3}
          multiline
          onChange={handleDescriptionChange}
          label={t('Reading question')}
          value={label}
          variant="outlined"
        />
      </Box>
      <Box  sx={{ display: "grid", maxWidth: "600px", justifySelf: "center", width: "100%"}}>
        {!isValidReadingQuestion &&
          <Alert sx={{justifyContent: "center", marginTop: "3px"}} severity="warning">{t('The description is missing')}</Alert>
        }
      </Box>
      <Box sx={{ maxWidth: "600px", justifySelf: "center", width: "100%", marginRight: "-20px" }}>
        <SaveCancel handleSave={handleSave} handleClose={handleClose} />
      </Box>
    </Box>
  )
}

export default InteractionModalReadingQuestion
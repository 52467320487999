import { t } from "i18next"
import { ConversationItem } from "../../../shared/src/types/Miscs"
import { useStore } from "../../../contexts/store"
import { observer } from "mobx-react-lite"
import UserInfoAvatar from "../../Elements/UserInfoAvatar"
import TimeFromNow from "../../Elements/TimeFromNow"

import { AvatarGroup, Box, Typography } from "@mui/material"


const ConversationListItem = ({item, podId, activeConversationId}: {item: ConversationItem, podId: string, activeConversationId: string | undefined}) => {
  const { podStore } = useStore()
  const userInfo = podStore.getUserInfo(item.userId, podId)
  const userName = userInfo ? userInfo.userName : t('unknown')

  return (
    <Box sx={{
      backgroundColor: (activeConversationId === item.interactionId) ? "#f6f8fe" : "",
      borderBottom: "1px solid rgba(63, 67, 80, 0.16)",
      borderLeft: "1px solid rgba(63, 67, 80, 0.16)",
      display: "grid",
      gridTemplateRows: "min-content min-content min-content",
      padding: "20px",
      "&:hover": {
        backgroundColor: (activeConversationId === item.interactionId) ? "#f0f3fc" : "#fcfcfc",
        cursor: "pointer"
      }
    }}>
      {/* Item Header */}
      <Box sx={{
        display: "grid",
        gridTemplateColumns: "auto max-content"
      }}>
        <Box sx={{fontWeight: "600", color: "#4c4c57", fontSize: "15px", wordBreak: "break-word"}}>
          {userName}
        </Box>
        <Box sx={{marginLeft: "15px", color: "#808080a6", fontSize: "13px"}}>
          <TimeFromNow timestamp={item.tLastMessage ? item.tLastMessage*1000 : 0} />
        </Box>
      </Box>
      {/* Item Text */}
      <Box sx={{
        color: "rgba(0, 0, 0, 0.87)",
        margin: "5px 0 20px 0",
        minHeight: "50px",
        wordBreak: "break-word",
        whiteSpace: "pre-line",
      }}>
        <Typography
          sx={{
            display: "-webkit-box",
            padding: "0 5px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}>
            {item.label}
        </Typography>
      </Box>
      {/* Item involved users */}
      <Box sx={{
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "max-content max-content 1fr",
        justifyContent: "start"
      }}>
        <AvatarGroup
          // add styling for surplus element
          componentsProps={{
            additionalAvatar: {
              sx: {
                borderRadius: "5px",
                fontSize:"12px",
                height: "25px",
                paddingLeft: "2px",
                width: "25px"
              }
            }
          }}
          variant="square"
          max={4}
        >
          {item.involvedUsers.map((userId: number) =>
            <UserInfoAvatar key={userId} sx={{fontSize:"12px", height: "25px", width: "25px"}} userId={userId} podId={podId}/>
          )}
        </AvatarGroup>
        <Box sx={{
          color: "#646464",
          paddingLeft: "10px",
          fontSize: "13px",
          fontWeight: 500,
        }}>
          {item.replies} {item.replies > 1 ? t('replies') : t('reply')}
        </Box>
        {/* unseen icon */}
        <Box sx={{display: "grid", justifyContent: "end"}}>
          {item.hasUnreadMessages &&
            <Box sx={{backgroundColor: "#5d89ea", width: "8px", height: "8px", borderRadius: "50%"}} />
          }
        </Box>
      </Box>
    </Box>
  )
}

export default observer(ConversationListItem)
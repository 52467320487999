import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import React from "react"

import { Box, Button, Card, CardContent, Checkbox, CircularProgress, Stack, Typography } from "@mui/material"
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { useStore } from '../../../contexts/store'

import { humanReadableBytes } from "../../../helper/Helper";
import { iLink, Interaction, InteractionTypePlural } from "../../../shared/src/types/Interaction";
import { FolderOutlined, Warning } from "@mui/icons-material";
const interactionTypePluralLiterals = ['annotations', 'emotions', 'comments', 'links', 'weblinks', 'taggings', 'readingQuestions'] as const

const ImportablePdf = ({ i }: { i:number }) => {
  const { podStore, pdfImportStore } = useStore()
  const file = pdfImportStore.queue[i]
  const { t } = useTranslation()
  const pod = podStore.pod

  if (!file) return null

  const interactionChecklist:React.JSX.Element[] = []
  var nofImportables:number  = 0
  var nofInteractions:number = 0

  const interactionIsImportable = (interaction:Interaction) => {
    switch(interaction.interactionType) {
      case 'link':
        const link:iLink = interaction as iLink
        const linkData = pdfImportStore.uuidMap[link.linkId]
        if (linkData && linkData.attr.src && linkData.attr.dst) return true
        if (linkData && (linkData.attr.src || linkData.attr.dst)) return false
        break;
    }
    return true
  }

  interactionTypePluralLiterals.forEach((interactionType:InteractionTypePlural) => {
    if (file.shrimpData && file.shrimpData.node && file.shrimpData.node[interactionType]) {

      Object.keys(file.shrimpData.node[interactionType]).forEach((interactionId:string) => {
        if (file.shrimpData) {
          const interaction:Interaction = file.shrimpData.node[interactionType][interactionId]
          const isImportable = interactionIsImportable(interaction)
          nofImportables += (isImportable ? 1 : 0)
          nofInteractions++
          interactionChecklist.push(<li key={interactionId}>
            {t(interaction.interactionType)} {t('by')} {interaction.userName} {isImportable ? <CheckCircleOutlineOutlinedIcon color='success' style={{verticalAlign:'text-bottom', height:'20px'}} /> : <ErrorOutlineOutlinedIcon color='error' style={{verticalAlign:'text-bottom', height:'20px'}} />}
          </li>)
        }
      })

  }})

  var info = null
  if (file.file) {
    var hashCollisionNodeId:string|undefined = ''
    if ((file.shrimpData?.node.hash) && (hashCollisionNodeId=Object.keys(pod?.content.pdfFiles || {}).find(pI => pod?.content.pdfFiles[pI].hash === file.shrimpData?.node.hash) ) && (hashCollisionNodeId)) {
      info = <div title={t('{{file}} also has {{hash}}', {file: pod?.content.pdfFiles[hashCollisionNodeId].name, hash: file.shrimpData?.node.hash }) as string} style={{display:'flex'}}>
        <Warning sx={{fontSize:'130%', marginRight:'4px', color:'orange'}}/>{t('A file with the same hash already exists in the pod')}</div>
    }
    if ((file.shrimpData?.context.folder)) info = <>{info}<div style={{display:'flex'}}><FolderOutlined sx={{fontSize:'130%', marginRight:'4px'}}/>{t('Importing to new folder "{{folderName}}"', {folderName: file.shrimpData?.context.folder.name})}</div></>
    if (nofInteractions) info = <>{info}<Typography>{`${humanReadableBytes(file.file?.byteLength || 0)}`} / {t('Importing {{nofImportables}} of {{nofInteractions}} embedded interactions', {nofImportables, nofInteractions})}</Typography></>
  }
  else {
    info = <Box>{t('This does not seem to be a PDF file.')} <Button variant="outlined" onClick={() => { pdfImportStore.queueModFile(i, file.originalRawFile || null) }}>{t(`I'm sure it is`)}</Button></Box>
  }

  const interactionSelection = interactionChecklist.length ?
    <ol style={{maxHeight:110, overflow:'auto', margin:0, backgroundColor:'#f8f8f8', border:'1px solid #ccc'}}>{interactionChecklist}</ol>
    : null

  const lineLead = (file.status === 'parsed') ?
    <Checkbox disabled={!Boolean(file.file)} checked={Boolean(file.doImport)} onChange={() => { pdfImportStore.queueModDoImport(i, !Boolean(file.doImport)) }} sx={{paddingTop:'4px'}} />
    : <CircularProgress size={24} sx={{marginLeft:'9px', marginRight: '9px'}} />

  return <Card sx={{ marginBottom:1, border: '1px solid #aaa', opacity: (Boolean(file.file) && (file.status === 'parsed')) ? 1 : .5 }}>
  <CardContent>
    <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-start", alignItems: "flex-start", }}>
      {lineLead}
      <Box>
        <Typography variant="h6">{
          file.shrimpData?.node?.name ?
            t('{{originalFilename}} as "{{givenFilename}}"', {originalFilename: file.originalFileName, givenFilename: file.shrimpData?.node?.name})
            : file.originalFileName
        }</Typography>
        {info}
        {interactionSelection}
      </Box>
    </Stack>
  </CardContent>
</Card>

}

export default observer(ImportablePdf)
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { useStore } from "../../contexts/store"
import { useTranslation } from "react-i18next"
import { Box, Paper, TextField } from "@mui/material"
import { navigate } from "wouter/use-location"
import { Op_editAnnotation } from "../../shared/src/types/Ops"
import { Interaction } from "../../shared/src/types/Interaction"
import SaveCancel from "./SaveCancel"
import BlockquoteBox from "./BlockquoteBox"


const Note = ({ backUrl, interaction, podId} : {backUrl: string, interaction: Interaction, podId: string}) => {
  const { t } = useTranslation()
  const { opStore, podStore, sessionStore } = useStore()
  const [shiftIsPressed, setShiftIsPressed] = useState(false)

  const [text, setText] = useState("")
  const textRef = useRef("")
  const interactionId = interaction.interactionId
  const textInput = interaction.label

  // keep last text input
  useEffect(() => {
    textRef.current = text
  }, [text])

  // on mount
  useEffect(() => {
    // set interaction text
    if(textInput) {
      setText(textInput)
      textRef.current = textInput
    }
    else setText("")

    // on unmount: save last text input
    return () => {
      if ((textRef.current !== textInput) && (podStore.pod?.getInteraction(interactionId))) submitChange()
    }
  }, [textInput])

  function submitChange() {
    if(podId && interactionId) {
      const opEditAnn:Op_editAnnotation = {
        op: 'editAnnotation',
        podId: podId,
        data: {
          userId: sessionStore.session.user.userId,
          userName: podStore.userPseudonym as string,
          usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
          interactionId: interactionId,
          mods: {
            label: textRef.current
          }
        }
      }
      opStore.doOp(opEditAnn)
    }
  }

  const handleSave = () => {
    navigate(`${backUrl}`, {replace: true})
  }

  const handleCancel = () => {
    textRef.current = textInput
    navigate(`${backUrl}`, {replace: true})
  }

  function handleTextareaChange(e: ChangeEvent<HTMLInputElement>) {
    setText(e.target.value)
  }

  return (
    <Box sx={{display: "grid", gridTemplateRows: "min-content max-content max-content auto"}}>
      <BlockquoteBox interaction={interaction} />
      <Box sx={{
        color: "#31394b",
        fontSize: "22px",
        fontWeight: "lighter",
        padding: "20px 0 0 20px",
      }}>
        {t('Notes')}
      </Box>
      <Paper
        elevation={2}
        sx={{
          backgroundColor: "#ffea77",
          display: "grid",
          gridTemplateRows: "auto min-content",
          margin: "15px 20px 0px 20px",
        }}>
          <TextField
            autoFocus
            id="outlined-multiline-flexible"
            minRows={10}
            multiline
            onChange={handleTextareaChange}
            onKeyDown={(ev) => {
              if(ev.key === 'Shift') {
                setShiftIsPressed(true)
              }
              if(ev.key === 'Enter' && shiftIsPressed) {
                handleSave()
                ev.preventDefault()
              }
              if(ev.key === 'Escape') {
                handleCancel()
                ev.stopPropagation()
                ev.preventDefault()
              }
            }}
            onKeyUp={(ev) => {
              if(ev.key === 'Shift') setShiftIsPressed(false)
            }}
            value={text}
          />
      </Paper>
      <Box sx={{ display: "grid", alignSelf: "end", margin: "20px 10px" }}>
        <SaveCancel handleSave={handleSave} handleClose={handleCancel} />
      </Box>
    </Box>
  )
}

export default observer(Note)
import { iTag } from "../../shared/src/types/Interaction"
import { useStore } from "../../contexts/store"
import { Chip } from "@mui/material"

const TaggingChip = ({interaction}: {interaction: iTag}) => {
  const { podStore } = useStore()

  // only show tag if there is a tag property
  const tagProp = podStore.getTagProp(interaction.tagId)
  if(!tagProp) return null

  return (
    <Chip color="secondary" variant="outlined" label={`# ${tagProp.name}`} />
  )
}

export default TaggingChip
import { t } from "i18next"
import { Box, CircularProgress } from "@mui/material"

const LoadingPdf = () => {
  return (
    <Box sx={{height: "45vh", width: "100%", justifyContent: "center", alignContent: "end", display: "grid", gridTemplateColumns: "max-content max-content", alignItems: "center", gap: "12px", color: "grey"}}>
      <CircularProgress size={14} />
      {t('Loading document')}
    </Box>
  )
}

export default LoadingPdf
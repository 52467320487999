import { Box } from "@mui/material"
import InteractionIcon from "../../Elements/InteractionIcon"
import { OpCode } from "../../../shared/src/types/OpCodes"

const IconStream = ({interactionType}: {interactionType: OpCode}) => {
  const backgroundColor = "#ececec"
  return (
    <Box sx={{
        display: "grid",
        gridTemplateRows: "auto min-content auto",
        justifyItems: "center",
      }}>
      <Box sx={{
          backgroundColor: backgroundColor,
          width: "3px"
        }}
      />
      <Box sx={{
          alignItems: "center",
          backgroundColor: backgroundColor,
          borderRadius: "50%",
          color: "#3e3e3e",
          display: "grid",
          height: "44px",
          justifyContent: "center",
          width: "44px"
        }}>
        <InteractionIcon interactionType={interactionType} />
      </Box>
      <Box sx={{
          backgroundColor: backgroundColor,
          width: "3px"
        }}
      />
    </Box>
  )
}

export default IconStream
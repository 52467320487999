import { t } from "i18next"
import { ChangeEvent, useEffect, useState } from "react"
import { useStore } from "../../../contexts/store"
import { Alert, Box, TextField } from "@mui/material"
import AddUserStepperButtons from "./AddUserStepperButtons"
import PersonIcon from '@mui/icons-material/Person'
import { notUserNameRegex, usernameMaxLength } from "../../../shared/src/validation/validationConstantsString"

const AddUserStepperPeople = (
  {activeStep, handleBack, handleNext, steps}: {
    activeStep: number,
    handleBack: () => void,
    handleNext: () => void,
    steps: string[]
  }) => {
  const { uiStore } = useStore()
  const [text, setText] = useState("")

  useEffect(() => {
    const userPad = uiStore.instantUserPad
    // restore input if available
    if(userPad) setText(userPad)
  }, [uiStore, uiStore.instantUserPad])

  function handleTextareaChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    setText(value)
    uiStore.updateInstantUserPad(value)
  }

  function getUserList() {
    return text.split(/\r?\n/g).filter(user => user !== '')
  }

  const hasUsernameError = () => {
    const userList = getUserList()
    for(const user of userList) {
      if(user.length > usernameMaxLength) return true
      if(user.match(notUserNameRegex) !== null) return true
    }
    return false
  }

  const disableNext = () => {
    if(getUserList().length <= 0) return true
    if(hasUsernameError()) return true
    return false
  }

  const handleNextStep = () => {
    // seperate user by linebreak
    const userList = getUserList()
    // generate login credentials
    uiStore.generateInstantUserList(userList)
    handleNext()
  }

  return (
    <Box sx={{display: "grid", gridTemplateRows: "max-content auto max-content max-content", height: "100%"}}>
      <Box sx={{margin: "20px 0", padding: "10px 20px", color: "grey"}}>
        {t('addUserStepper_header_people')}
      </Box>
      <Box>
        <TextField
          autoFocus
          error={hasUsernameError()}
          id="outlined-multiline-flexible"
          multiline
          fullWidth
          onChange={handleTextareaChange}
          rows={15}
          value={text}
        />
        <Box sx={{
          color: "grey",
          display: "grid",
          gridTemplateColumns: "max-content max-content",
          justifyContent: "end",
          margin: "5px 10px 15px 10px"
        }}>
          <PersonIcon />
          {getUserList().length}
        </Box>
      </Box>
      <Box>
        {hasUsernameError() &&
          <Alert severity="error">
            <Box>{t('The names of the users may contain a maximum of {{maxLength}}  characters.', {maxLength: usernameMaxLength})}</Box>
            <Box>{t('No special characters may be included.')}</Box>
          </Alert>
        }
      </Box>
      <AddUserStepperButtons disabledNext={disableNext()} activeStep={activeStep} handleBack={handleBack} handleNext={handleNextStep} steps={steps} />
    </Box>
  )
}

export default AddUserStepperPeople
import React from "react"
import { Box, Slider } from "@mui/material"
import { useStore } from "../../../contexts/store"
import { observer } from "mobx-react-lite"

const ZoomSlider = ({pageListRef, storeId}: {pageListRef: any, storeId: string}) => {

  const { pdfMetaStore, uiStore } = useStore()
  const scale = pdfMetaStore.getScale(storeId)
  const percentScale = Math.round(scale*100)

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      pdfMetaStore.setScale(storeId, (newValue/50)+.5)
      if(pageListRef && pageListRef.current && pageListRef.current.props) {
        const scaleAnchor = {
          x: Math.round(pageListRef.current.props.width/2 * 100)/100,
          y: Math.round(pageListRef.current.props.height/2 * 100)/100
        }
        pdfMetaStore.setScaleAnchor(storeId, scaleAnchor)
      }
    }
  }

  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "min-content min-content", alignItems: "center", gridGap: "10px", marginLeft: "5px" }}>
      <Slider
        size="small"
        aria-label="zoom-factor"
        valueLabelDisplay="off"
        value={(scale-.5)*50}
        onChange={handleChange}
        sx={{
          width: (uiStore.deviceWidth === "tiny") ? "80px" : "100px",
          boxShadow: "none",
          "& .MuiSlider-thumb": {
            borderRadius: 0,
            height: 13,
            width: 6,
            "&:focus, &:hover, &.Mui-active": {
              "WebkitBoxShadow": "none",
              boxShadow: "none"
            },
            "& .Mui-focusVisible": {
              "WebkitBoxShadow": "none",
              boxShadow: "none"
            },
            "&::after": {
              display: "none"
            }
          }
        }}
      />
      <Box sx={{fontSize: "12px", minWidth: "30px"}}>
        {percentScale}%
      </Box>
    </Box>
  )
}

export default observer(ZoomSlider)

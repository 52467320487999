import { InteractionType } from '../../shared/src/types/Interaction'
import { OpCode } from '../../shared/src/types/OpCodes'
import AnnotationIcon from '@mui/icons-material/FormatColorText'
import CommentIcon from '@mui/icons-material/Comment'
import LinkIcon from '@mui/icons-material/Link'
import TagIcon from '@mui/icons-material/Sell'
import WeblinkIcon from '@mui/icons-material/OpenInNew'
import EmotionIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import AddReactionIcon from '@mui/icons-material/AddReactionOutlined'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import ReadingQuestionIcon from '@mui/icons-material/ContactSupport'
import { PersonAdd, PersonRemove } from '@mui/icons-material'
import PasswordIcon from '@mui/icons-material/Key'
import MyPasswordIcon from '@mui/icons-material/VpnKey'


const InteractionIcon = ({interactionType, fontSize}: {interactionType: InteractionType | 'message' | OpCode | null | undefined, fontSize?: "small" | "medium" | "large"}) => {
  // cast OpCodes into InteractionTypes
  if (
    interactionType !== "annotation" &&
    interactionType !==  "comment" &&
    interactionType !==  "link" &&
    interactionType !==  "tagging" &&
    interactionType !== "weblink" &&
    interactionType !==  "emotion" &&
    interactionType !==  "message" &&
    interactionType !==  "readingQuestion"
  ) {
    if(interactionType === "addAnnotation" || interactionType === "editAnnotation" || interactionType === "deleteAnnotation") interactionType = "annotation"
    if(interactionType === "addComment" || interactionType === "editComment" || interactionType === "deleteComment") interactionType =  "comment"
    if(interactionType === "addLink" || interactionType === "editLink" || interactionType === "deleteLink") interactionType =  "link"
    if(interactionType === "addTagging" || interactionType === "editTagging" || interactionType === "deleteTagging") interactionType =  "tagging"
    if(interactionType === "addWeblink" || interactionType === "editWeblink" || interactionType === "deleteWeblink") interactionType =  "weblink"
    if(interactionType === "addEmotion" || interactionType === "editEmotion" || interactionType === "deleteEmotion") interactionType =  "emotion"
    if(interactionType === "addMessage" || interactionType === "editMessage" || interactionType === "deleteMessage") interactionType =  "message"
    if(interactionType === "addReadingQuestion" || interactionType === "editReadingQuestion" || interactionType === "deleteReadingQuestion") interactionType =  "readingQuestion"
  }
  // return interaction icon
  if(interactionType === "annotation") return <AnnotationIcon fontSize={fontSize ? fontSize : "medium"} />
  if(interactionType === "comment") return <CommentIcon fontSize={fontSize ? fontSize : "medium"}  />
  if(interactionType === "link") return <LinkIcon fontSize={fontSize ? fontSize : "medium"}  />
  if(interactionType === "tagging") return <TagIcon fontSize={fontSize ? fontSize : "medium"}  />
  if(interactionType === "weblink") return <WeblinkIcon fontSize={fontSize ? fontSize : "medium"}  />
  if(interactionType === "emotion") return <EmotionIcon fontSize={fontSize ? fontSize : "medium"}  />
  if(interactionType === "message") return <CommentIcon fontSize={fontSize ? fontSize : "medium"}  />
  if(interactionType === "readingQuestion") return <ReadingQuestionIcon fontSize={fontSize ? fontSize : "medium"}  />
  if(interactionType === "addReaction") return <AddReactionIcon fontSize={fontSize ? fontSize : "medium"}  />
  if(interactionType === "addUserToPod") return <PersonAdd fontSize={fontSize ? fontSize : "medium"}  />
  if(interactionType === "removeUserFromPod") return <PersonRemove fontSize={fontSize ? fontSize : "medium"}  />
  if(interactionType === "addPdfFile") return <NoteAddIcon fontSize={fontSize ? fontSize : "medium"}  />
  if(interactionType === "changeUserPassword") return <PasswordIcon fontSize={fontSize ? fontSize : "medium"}  />
  if(interactionType === "changeMyPassword") return <MyPasswordIcon fontSize={fontSize ? fontSize : "medium"}  />
  return null
}

export default InteractionIcon
import { OpCode } from "../shared/src/types/OpCodes";

export const activityOps:OpCode[] = [
  'addPdfFile',
  'addComment',
  'addEmotion',
  'addLink',
  'addTagging',
  'addReadingQuestion',
  'addWeblink',
  'addMessage',
  'addReaction',
  'editTag',
  'addUserToPod',
  'removeUserFromPod',
]
import { useEffect, useState } from "react"
import { useStore } from "../../contexts/store"
import { createPortal } from "react-dom"
import { InteractionType } from "../../shared/src/types/Interaction"
import Marginalia from "./Marginalia/Marginalia"


const MarginaliaOverlay = ({
    childrenDepth,
    interactionEditId,
    interactionsShown,
    pageId,
    podId,
    nodeId,
    pageNumber
  }: {
    childrenDepth: number,
    interactionEditId: string | undefined,
    interactionsShown: InteractionType[],
    pageId: string,
    podId: string,
    nodeId: string,
    pageNumber: number
}) => {
  const { podStore } = useStore()
  const [ pageContainer, setPageContainer ] = useState<any>(null)

  useEffect(() => {
    const pageRef = document.getElementById(pageId)
    if(pageContainer === null && pageRef) setPageContainer(pageRef)
  }, [pageContainer, pageId])

  // get pod object
  const pod = podStore.pod
  // do not show marginalia on edit pages
  if(pod === null || interactionEditId) return null

  // test if page exists
  if(pageContainer === null || pageContainer.children[childrenDepth] === null) return null
  // get page inside pdf container
  const page = pageContainer.children[childrenDepth]

  return (
    createPortal(
      <div id={`marginalia-test-page-${pageNumber}`}>
        <Marginalia
          pageNumber={pageNumber}
          pdfId={nodeId}
          podId={podId}
          interactionsShown={interactionsShown}
        />
      </div>
      ,page
    )
  )

}

export default MarginaliaOverlay
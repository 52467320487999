import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { useStore } from "../../../contexts/store"
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import { Edit, MoreHoriz, Visibility, VisibilityOff, ExpandMore, Delete } from '@mui/icons-material'
import FolderIcon from '@mui/icons-material/FolderOutlined'
import FolderIconOpen from '@mui/icons-material/FolderOpenOutlined'
import { Folder as FolderType } from '../../../shared/src/types/Content'
import { Op_deleteFolder, Op_editFolder } from "../../../shared/src/types/Ops"
import uiStore from "../../../stores/uiStore"
import EditFolderDialog from "./EditFolderDialog"
import { PodClass } from "../../../classes/Pod"
import ReadingProgress from "../../Elements/ReadingProgress"

// message from thread
const Folder = ({isAdmin, folder, fileListing}: {isAdmin: boolean, folder: FolderType, fileListing: (folderId: string) => JSX.Element[] }) => {
  const [ anchorEl, setAnchorEl ] = React.useState<null | Element>(null)
  const [ showDialog, setShowDialog ] = React.useState<boolean>(false)
  const { podStore, sessionStore, opStore } = useStore()
  const { t } = useTranslation()
  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const handleClick = (event: React.MouseEvent<Element>) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleClose = (event:React.MouseEvent) => {
    setAnchorEl(null)
    event.stopPropagation()
  }

  const toggleVisibility = () => {
    const op:Op_editFolder = {
      op: 'editFolder',
      podId: pod.podId,
      data: {
        folderId: folder.folderId,
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userId: sessionStore.session.user.userId,
        userName: podStore.userPseudonym || '',
        mods: {
          hidden: (folder.hidden) ? false : true
        }
      }
    }
    opStore.doOp(op)
  }

  const deleteFolder = () => {
    const op:Op_deleteFolder = {
      op: 'deleteFolder',
      podId: pod.podId,
      data: {
        folderId: folder.folderId,
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userId: sessionStore.session.user.userId,
        userName: podStore.userPseudonym || '',
      }
    }
    opStore.doOp(op)
  }

  var visibility = {
      style: { opacity: 1 },
      text: t('Hide from regular pod users'),
      icon: <VisibilityOff />
  }

  if (folder.hidden) visibility = {
    style: { opacity: .3 },
    text: t('Show to regular pod users'),
    icon: <Visibility />
  }

  const expanded = (uiStore.folderIsOpen[pod.podId] && uiStore.folderIsOpen[pod.podId][folder.folderId]) ? true : false

  const expandStyle = {
    transform:'rotate('+(expanded ? 180 : 0)+'deg)',
    transition: 'transform .2s'
  }

  return <ul style={{margin: "5px 0", listStyleType: "none", padding: 0, ...visibility.style}} >
    <Accordion
      expanded={expanded} onChange={() => { uiStore.setFolderIsOpen(pod.podId, folder.folderId, !(uiStore.folderIsOpen[pod.podId] && uiStore.folderIsOpen[pod.podId][folder.folderId])) }}>
      <AccordionSummary
        aria-controls="panel-content"
        style={{margin:'2px 0 2px 0'}}
      >
        <Box style={{width:'100%'}} sx={{display: 'grid', gridTemplateColumns: 'max-content auto min-content max-content max-content'}}>
          {expanded ? <FolderIconOpen sx={{mr:1}} />: <FolderIcon sx={{mr: 1}} />}
          {folder.name}
          <Box sx={{margin: "0 26px 0 10px"}}>
            <ReadingProgress folderId={folder.folderId} />
          </Box>
          <ExpandMore style={expandStyle} />
          {isAdmin &&
            <IconButton size='small' onClick={(e:React.MouseEvent) => { handleClick(e)} }>
              <MoreHoriz fontSize='small' />
            </IconButton>
          }
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {fileListing(folder.folderId)}
      </AccordionDetails>
    </Accordion>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
    }}
    >
      <MenuItem  onClick={(e:React.MouseEvent) => { setShowDialog(true); handleClose(e); e.stopPropagation() }}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText>{t('Edit this folder')}</ListItemText>
      </MenuItem>

      <MenuItem onClick={(e:React.MouseEvent) => { toggleVisibility(); handleClose(e); e.stopPropagation() }}>
        <ListItemIcon>
          {visibility.icon}
        </ListItemIcon>
        <ListItemText>{visibility.text}</ListItemText>
      </MenuItem>

      <MenuItem onClick={(e:React.MouseEvent) => { deleteFolder(); handleClose(e); e.stopPropagation() }}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText>{t('Delete this folder')}</ListItemText>
      </MenuItem>

    </Menu>
    <EditFolderDialog folder={folder} open={showDialog} handleClose={() => { setShowDialog(false)}} />
  </ul>

}

export default observer(Folder)
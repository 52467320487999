import React, { createRef, useEffect } from 'react'
// components
import { baseUrl } from '../../api/api'
import Thumbnails from '../Pdf/Thumbnails/Thumbnails'
import Viewer from '../Pdf/Viewer'
// mui
import { useStore } from '../../contexts/store'
import { observer } from 'mobx-react-lite'
import ErrorBoundaryComponent from '../ErrorBoundaryComponent'
import InteractionDrawer from '../Pdf/InteractionDrawer/InteractionDrawer'
import { Box } from '@mui/material'
import TransformInteractionMenu from '../Elements/TransformInteractionMenu'
import OverlappingInteractionsMenu from '../Elements/OverlappingInteractionsMenu'
import PdfFooter from '../Pdf/PdfFooter'

// prevent observing stores, prop change will not reload page
import uiStore from '../../stores/uiStore'
import pdfMetaStore from '../../stores/pdfMetaStore'

const Pdf = ({pdfId}: {pdfId:string|undefined}) => {
  const { podStore, sessionStore } = useStore()
  const pod = podStore.pod
  const pdfUrl = `${baseUrl}/pdf/${pdfId}`
  const thumbnailUrl = `${baseUrl}/pageView/${pdfId}`
  const pageListRef: any = createRef()
  const thumbListRef: any = createRef()

  useEffect(() => {
    const storeId = pdfUrl

    // calculate pinch zoom scale
    const pinchZoom = (touches: TouchList) => {
      const numberOfTouches = touches.length
      // pinch zoom, if there are still two fingers on screen
      if(numberOfTouches === 2) {
        const activeTouchA = touches[0]
        const activeTouchB = touches[1]
        // get actual dist between fingers
        const dist = Math.hypot(
          activeTouchA.pageX - activeTouchB.pageX,
          activeTouchA.pageY - activeTouchB.pageY
        )
        // store pinch zoom center
        const xAnchor = (activeTouchA.pageX + activeTouchB.pageX)/2
        const yAnchor = (activeTouchA.pageY + activeTouchB.pageY)/2
        pdfMetaStore.setScaleAnchor(storeId, {x: xAnchor, y: yAnchor})
        // set new scale
        const pinchStartDistance = pdfMetaStore.getPinchStartDistance(storeId)
        const pinchStartScale = pdfMetaStore.getPinchStartScale(storeId)
        if(pinchStartDistance && pinchStartScale ) {
          const pinchScale = pinchStartScale * (dist / pinchStartDistance)
          pdfMetaStore.setScale(storeId, pinchScale)
        }
      }
    }

    // dom persistent touch move listener
    const onTouchTargetMove = (e: any) => {
      pinchZoom(e.touches)
    }

    const onTouchStart = (e: TouchEvent) => {
      const numberOfTouches = e.touches.length
      const eventTarget: EventTarget | null = e.target
      if(eventTarget) {
        if(numberOfTouches === 2) {
          // prevent device gesture recognition for pinch zoom
          e.preventDefault()
          // store initial scale and dist between fingers
          const activeTouchA = e.touches[0]
          const activeTouchB = e.touches[1]
          const dist = Math.hypot(
            activeTouchA.pageX - activeTouchB.pageX,
            activeTouchA.pageY - activeTouchB.pageY
          )
          pdfMetaStore.setPinchStartDistance(storeId, dist)
          pdfMetaStore.updatePinchStartScale(storeId)

          // add dom change persistent touch move listener on event target of touch start
          eventTarget.addEventListener("touchmove",(event) => onTouchTargetMove(event))
          // remove move listener if pinch zoom is finished
          // use once attribute to save the trouble of cancelling the end listener
          eventTarget.addEventListener("touchend", () => {
            eventTarget.removeEventListener("touchmove", (event) => onTouchTargetMove(event))
            pdfMetaStore.setPinchStartDistance(storeId, null)
          }, { once: true })
        }
      }
    }
    const onTouchMove = (e: TouchEvent) => {
      pinchZoom(e.touches)
    }
    const onTouchEnd = (e: TouchEvent) => {
      pdfMetaStore.setPinchStartDistance(storeId, null)
    }
    const onTouchCancel = (e: TouchEvent) => {
      onTouchEnd(e)
    }
    const handleWheel = (e: WheelEvent) => {
      // scroll wheel with ctrlKey change pdf scale
      if (e.ctrlKey) {
        e.preventDefault()
        e.stopPropagation()
        // store coordinates under mouse for zooming
        pdfMetaStore.setScaleAnchor(pdfUrl, {x: e.clientX, y: e.clientY})
        // change scale
        const scale = pdfMetaStore.getScale(pdfUrl)
        if (e.deltaY>0) pdfMetaStore.setScale(pdfUrl, scale-.1)
        else pdfMetaStore.setScale(pdfUrl, scale+.1)
      }
    }

    // init touch event listener
    const touchElement = document.getElementById(`${storeId}-pdf-viewer-container`)
    if(touchElement) {
      touchElement.addEventListener("touchstart", onTouchStart)
      touchElement.addEventListener("touchmove", onTouchMove)
      touchElement.addEventListener("touchend", onTouchEnd)
      touchElement.addEventListener("touchcancel", onTouchCancel)
      touchElement.addEventListener('wheel', handleWheel)
    }

    // on unmount: remove touch event listener
    return () => {
      const touchElement = document.getElementById(`${storeId}-pdf-viewer-container`)
      if(touchElement) {
        touchElement.removeEventListener('touchstart', onTouchStart)
        touchElement.removeEventListener("touchmove", onTouchMove)
        touchElement.removeEventListener("touchend", onTouchEnd)
        touchElement.removeEventListener("touchcancel", onTouchCancel)
        touchElement.removeEventListener('wheel', handleWheel)
      }
    }
  })



  if (!pdfId) return  <div>Could not render the pdf file. PdfId missing.</div>
  if (!pod) return <div>Could not render the pdf file "{pdfId}". Pod missing.</div>

  const podId = pod.podId
  const file = pod.content.pdfFiles[pdfId]
  if (!file) return <div>Could not render the pdf file. No Pdf file found.</div>

  if (pod.nodeIsHidden(pdfId) && file.userId !== sessionStore.session.user.userId) return <div>Could not render the pdf file (1).</div>
  if (!pod.isVisible('pdfFile', pdfId)) return <div>Could not render the pdf file (2).</div>

  return <ErrorBoundaryComponent>
    <Box sx={{display: "grid", gridTemplateRows: "auto min-content", overflowY: "auto"}}>
      <Box id={`${pdfUrl}-pdf-viewer-container`} sx={{
        backgroundColor: "whitesmoke",
        display: "grid",
        gridTemplateColumns: uiStore.deviceWidth === "tiny" ? "auto" : `auto ${uiStore.getThumbContainerWidth()}px`,
        overflowY: "auto",
      }}>
        <ErrorBoundaryComponent context="Pdf>Viewer">
          <Viewer
            pageListRef={pageListRef}
            pdfId={pdfId}
            pdfUrl={pdfUrl}
            podId={podId}
            storeId={pdfUrl}
            thumbListRef={thumbListRef}
            thumbnailUrl={thumbnailUrl}
          />
        </ErrorBoundaryComponent>
        <Thumbnails
          pageListRef={pageListRef}
          storeId={pdfUrl}
          thumbListRef={thumbListRef}
          thumbnailUrl={thumbnailUrl}
        />
        <InteractionDrawer
          pageListRef={pageListRef}
          storeId={pdfUrl}
        />
        <TransformInteractionMenu />
        <OverlappingInteractionsMenu podId={podId} pdfId={pdfId} />
      </Box>
      <PdfFooter pageListRef={pageListRef} storeId={pdfUrl} />
    </Box>
  </ErrorBoundaryComponent>
}

export default observer(Pdf)

import { t } from "i18next"
import { useStore } from "../../../contexts/store"
import { Box, Checkbox, Divider, FormControlLabel, Popover, ToggleButton, Tooltip } from "@mui/material"
import { observer } from "mobx-react-lite"
import { Visibility } from "@mui/icons-material"
import { useState } from "react"
import { InteractionType } from "../../../shared/src/types/Interaction"
import { VisibilitySelectable } from "../../../stores/uiStore"

const VisibilitySelection = () => {
  const { uiStore, podStore } = useStore()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
//  const [ selection, setSelection ] = useState<{[interactionType:string]: boolean}>({})
  const pod = podStore.pod
  const selection = uiStore.hideInteractions
  if (!pod) return null

  const selectionOptions:VisibilitySelectable[] = [ /*'annotation',*/ 'comment', 'emotion', 'link', 'readingQuestion', 'tagging', 'weblink' ]

  const openDialog = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleChange = (option:VisibilitySelectable) => {
    uiStore.setHideInteraction(option, !selection[option])
  }

  const handleClose = () => {
    setAnchorEl(null)
  //  setSelection({})
  }

  return (
    <>
      <Tooltip placement="top" title={t('Change which interactions are shown in the PDF')}>
        <ToggleButton
          value="freehandSelection"
          selected={false}
          onClick ={openDialog}
          size="small"
        >
          <Visibility fontSize="small" />
        </ToggleButton>
      </Tooltip>
      <Popover open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right', }} transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}>
        <Box sx={{ p: 2 }}>
          <Box sx={{ pb: 1 }}>{t('Show the following interactions')}:</Box>
          {
            selectionOptions.map((option) => {
              return (<Box key={option}>
                <FormControlLabel control={<Checkbox sx={{ p: 1 }} checked={!selection[option]} onClick={(e) => handleChange(option as InteractionType)} />} label={t(option)} />
              </Box>)
            })
          }
          <Divider />
          <FormControlLabel control={<Checkbox sx={{ p: 1 }} checked={!selection['showUnseen']} onClick={(e) => handleChange('showUnseen' as InteractionType)} />} label={t('showUnseen')} />
        </Box>
      </Popover>
    </>

  )
}

export default observer(VisibilitySelection)
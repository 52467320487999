import { useLayoutEffect } from "react"
import { observer } from "mobx-react-lite"
import { useStore } from "../../contexts/store"
import { PodClass } from "../../classes/Pod"
import { Box, Paper } from "@mui/material"
import { iAnnotation } from "../../shared/src/types/Interaction"
import { Link } from "wouter"
import Blockquote from "../Elements/Blockquote"

const Notes = ({pod, initScrollPos}: {pod: PodClass, initScrollPos: (tab: string) => void}) => {
  const { uiStore } = useStore()

  useLayoutEffect(() => {
    initScrollPos("notes")
  })

  const notes: JSX.Element[] = []
  const pdfFiles = []
  for (let key in pod.content.pdfFiles) {
      if (pod.content.pdfFiles.hasOwnProperty(key)) pdfFiles.push(key)
  }

  const Annotation = ({annotation, fileId}: {annotation: iAnnotation, fileId: string}) => {
    const color = annotation.style.color ? annotation.style.color : uiStore.getInteractionColor('annotation')
    return (
      <Link to={`/pod/${pod.podId}/pdf/${fileId}/annotation/${annotation.interactionId}`} className="remove-link-decoration">
        <Paper elevation={3} sx={{display: "grid", p: "20px", m: "20px", "&:hover": {backgroundColor: "whitesmoke"} }}>
          <Blockquote color={color} hasToggleButton={false} text={annotation.anchor.relText} />
          <Box sx={{p: "5px 10px 10px 10px", mb: "10px"}}>
            {annotation.label}
          </Box>
        </Paper>
      </Link>
    )
  }

  for (const fileId of pdfFiles) {
    const annotations = pod.getAnnotations(fileId)
    annotations?.forEach(annotation => {
      if(annotation.label !== "") {
        notes.push(
          <li key={annotation.interactionId} style={{listStyle: "none"}}>
            <Annotation
              annotation={annotation}
              fileId={fileId}
            />
          </li>
        )
      }
    })
  }

  return (
    <Box>
      {notes}
    </Box>
  )
}

export default observer(Notes)
import React from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../contexts/store'
import { Box } from '@mui/material';
import { PodClass } from '../../classes/Pod';
import {
  PDFDocument,
  PDFName,
  PDFDict,
  PDFArray,
  PDFStream,
  decodePDFRawStream,
  PDFHexString,
  PDFString,
  PDFRawStream,
} from 'pdf-lib'
import { Interaction, InteractionTypePlural } from '../../shared/src/types/Interaction';
const interactionTypePluralLiterals = ['annotations', 'emotions', 'comments', 'links', 'weblinks', 'taggings', 'readingQuestions'] as const

type FileInfo = {
  file:ArrayBuffer,
  shrimpData: any,
}

const ShrimpPdfreader = () => {
  const { podStore } = useStore()
  const [ file, setFile ] = React.useState<null|FileInfo>(null)

  const pod:PodClass|null = podStore.pod

  function extractRawAttachments (pdfDoc: PDFDocument) {
    if (!pdfDoc.catalog.has(PDFName.of('Names'))) return [];
    const Names = pdfDoc.catalog.lookup(PDFName.of('Names'), PDFDict);

    if (!Names.has(PDFName.of('EmbeddedFiles'))) return [];
    const EmbeddedFiles = Names.lookup(PDFName.of('EmbeddedFiles'), PDFDict);

    if (!EmbeddedFiles.has(PDFName.of('Names'))) return [];
    const EFNames = EmbeddedFiles.lookup(PDFName.of('Names'), PDFArray);

    const rawAttachments = [];
    for (let idx = 0, len = EFNames.size(); idx < len; idx += 2) {
      const fileName = EFNames.lookup(idx) as PDFHexString | PDFString;
      const fileSpec = EFNames.lookup(idx + 1, PDFDict);
      rawAttachments.push({ fileName, fileSpec });
    }

    return rawAttachments;
  }

  function getShrimpData(pdfDoc: PDFDocument) {
    const rawAttachments = extractRawAttachments(pdfDoc);
    const shrimpAttachments = rawAttachments
      .map(({ fileName, fileSpec }) => {
        const stream = fileSpec
          .lookup(PDFName.of('EF'), PDFDict)
          .lookup(PDFName.of('F'), PDFStream) as PDFRawStream
        return {
          name: fileName.decodeText(),
          data: decodePDFRawStream(stream).decode(),
        };
      })
      .filter(attachment => attachment.name === 'Shrimp')
      if (shrimpAttachments.length === 1) {
        const string = new TextDecoder().decode(shrimpAttachments[0].data)
        return JSON.parse(string)
      }
      else {
        console.warn(`Found ${shrimpAttachments.length} Shrimp attachments`)
        return null
      }
  }

  const processPdf = async (buffer:ArrayBuffer) => {
    const pdfDoc = await PDFDocument.load(buffer)

    const shrimpData = getShrimpData(pdfDoc)

    // Strip all embeddings
    if (!pdfDoc.catalog.has(PDFName.of('Names'))) return [];
    const Names = pdfDoc.catalog.lookup(PDFName.of('Names'), PDFDict);
    if (!Names.has(PDFName.of('EmbeddedFiles'))) return [];
    Names.delete(PDFName.of('EmbeddedFiles'))

    setFile({
      file: await pdfDoc.save(),
      shrimpData
    })

  }

  const fileReader = async (e:React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = async function() {
        const fileData = this.result
        if ((fileData) && (typeof fileData === 'object')) {
          await processPdf(fileData)
        }
        e.target.value = ''
      }
      reader.readAsArrayBuffer(file)
    }
    return false
  }

  return <Box style={{
    height:'100%',
    width:'100%',
    overflow:'scroll'
  }}>
    <input type="file" onChange={fileReader} />
    { file ? <div>
        Import "{file.shrimpData.node.name}" ({file.file.byteLength} Bytes)
        {interactionTypePluralLiterals.map((interactionType:InteractionTypePlural) => {
          if (file.shrimpData.node[interactionType]) return <div key={interactionType}>
            {Object.keys(file.shrimpData.node[interactionType]).map((interactionId:string) => {
            const interaction:Interaction = file.shrimpData.node[interactionType][interactionId]
            return <div key={interactionId}>
              {interaction.interactionType} by {interaction.userName}
            </div>
            })}
          </div>
          return null
        })}
      </div> : null }
  </Box>
}

export default observer(ShrimpPdfreader)
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useStore } from '../../../contexts/store'
import SaveCancel from '../../Elements/SaveCancel'
import { iTag, interactionAnchor } from '../../../shared/src/types/Interaction'
import { Op_addTag, Op_addTagging, Op_deleteAnnotation, Op_editTagging } from '../../../shared/src/types/Ops'
import { Alert, Box, TextField } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { notTagNameRegex } from '../../../shared/src/validation/validationConstantsString'

const filter = createFilterOptions<TagOptionType>()

interface TagOptionType {
  inputValue?: string
  name: string,
  tagId?: string
}

const EditTag = ({
    anchor,
    interaction,
    podId,
    session
  }:
  {
    anchor: interactionAnchor | null,
    interaction: iTag,
    podId: string,
    session: any
  }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<TagOptionType | null>(null)
  const [showWarning, setShowWarning] = useState(false)
  const { podStore, uiStore, opStore, sessionStore } = useStore()
  const tagPool: TagOptionType[] = podStore.getTagPool()
  const isTransformation = (interaction.interactionType === "annotation") ? true : false

  useEffect(() => {
    // if tag is edited: load existing tag data
    if(interaction && !isTransformation) {
      const tagProp = podStore.getTagProp(interaction.tagId)
      const name = tagProp?.name
      if(name) {
        setValue({inputValue: name, name: name, tagId: interaction.tagId})
      }
    }
  }, [podStore, interaction, isTransformation])

  if(anchor === null) return null

  const handleClose = () => {
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
    // remove edited text selection
    if(interaction) podStore.deleteInteractionEditAnchor(interaction.interactionId)
  }

  const handleSave = () => {
    const tagName = (value && value.name) ? value.name.replace(notTagNameRegex,'') as string : null
    if(value && tagName) {
      let tagId = value.tagId
      // if tag does not exist: add tag to the pool of tags
      if(tagId === undefined) {
        tagId = sessionStore.createUuid()
        opStore.doOp({
          op: "addTag",
          podId: podId,
          data: {
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
            userId: session.user.userId,
            userName: podStore.userPseudonym,
            tagId: tagId,
            name: tagName,
            description: "",
            coid: null,
            tCreated: dayjs().unix(),
            tModified: dayjs().unix()
          }
        } as Op_addTag)
      }

      const editAnchor = podStore.getInteractionEditAnchor(interaction.interactionId)
      // distinguish whether tagging is edited or newly created
      if(!isTransformation) {
        opStore.doOp({
          op: "editTagging",
          podId: podId,
          data: {
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
            userId: session.user.userId,
            userName: podStore.userPseudonym,
            interactionId: interaction.interactionId,
            mods: {
              anchor: (editAnchor) ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(anchor)),
              tagId: tagId,
            }
          }
        } as Op_editTagging)
      }
        // transformation: add tagging, delete annotation
      else {
        const transformationAnchor = {
          nodeId: anchor.nodeId,
          relText: anchor.relText,
          rects: JSON.parse(JSON.stringify(anchor.rects)),
          tool: anchor.tool
        }
        opStore.doOp({
          op: "addTagging",
          podId: podId,
          data: {
            interactionType: 'tagging',
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
            interactionId: sessionStore.createUuid(),
            userId: session.user.userId,
            userName: podStore.userPseudonym,
            anchor: editAnchor ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(transformationAnchor)),
            style: { color: null },
            tagId: tagId,
            label: "",
            reactions: {},
            tCreated: dayjs().unix(),
            tModified: dayjs().unix(),
            coid:null,
            tSeen: dayjs().unix(),
            dSeen: 1
          }
        } as Op_addTagging)
        // delete annotation
        opStore.doOp({
          op: 'deleteAnnotation',
          podId,
          data: {
            userId: sessionStore.session.user.userId,
            userName: podStore.userPseudonym as string,
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
            interactionId: interaction.interactionId
          }
        } as Op_deleteAnnotation)
      }

      // close modal
      uiStore.closeInteractionModal()
      // delete selected anchor in store
      uiStore.setSelectedAnchor(null)
    }
    if(value === null || value.name === undefined) setShowWarning(true)
  }

  return (
    <Box sx={{display: "grid", alignItems: "end", gridTemplateRows: "max-content auto"}}>
      <Box  sx={{ display: "grid", margin: "5px 10px", maxWidth: "600px", justifySelf: "center", width: "100%"}}>
        <Autocomplete
          value={value}
          onChange={(_, newValue) => {
            // hide warning
            setShowWarning(false)
            if (typeof newValue === 'string') {
              setValue({
                name: newValue,
              })
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setValue({
                name: newValue.inputValue,
              })
            } else {
              setValue(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const inputValue = String(params.inputValue).replace(notTagNameRegex,'') as string

            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.name);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                name: `${t('Add')} "${inputValue}"`,
              });
            }

            return filtered
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="tag_selection"
          options={tagPool}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue
            }
            // Regular option
            return option.name
          }}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          freeSolo
          renderInput={(params) => (
            <TextField sx={{backgroundColor:"#dedefd"}} {...params} label="Tag" />
          )}
        />

      </Box>
      <Box sx={{ maxWidth: "600px", justifySelf: "center", width: "100%", marginRight: "-20px" }}>
        {showWarning && (
          <Alert sx={{marginTop: "5px", marginRight: "10px"}} severity="warning">{t('Please select tag')}</Alert>
        )}
        <SaveCancel handleSave={handleSave} handleClose={handleClose} />
      </Box>
    </Box>
  )
}

export default EditTag
import { useStore } from "../../../contexts/store"
import { iAnnotation } from "../../../shared/src/types/Interaction"
import { navigate } from "wouter/use-location"
import { observer } from "mobx-react-lite"
import { Box, Paper } from "@mui/material"

// shows interaction as marginalia icon
const MarginaliaNote = (
  {
    height,
    item,
    pdfUrl,
    width
  }: {
    height: number,
    item: iAnnotation,
    pdfUrl: string,
    width: number
  }
) => {
  const { uiStore } = useStore()
  const color = item.style.color ? item.style.color : uiStore.getInteractionColor(item.interactionType)
  // open drawer, if interaction is clicked
  const handleClick = (interactionType: string, interactionId: string) => {
    navigate(`${pdfUrl}/${interactionType}/${interactionId}`, {replace: true})
  }

  return (
    <Paper
      onClick={() => handleClick(item.interactionType, item.interactionId)}
      sx={{
        backgroundColor: "#ffea77",
        borderLeft: `8px solid ${color}`,
        color: "rgba(0, 0, 0, 0.87)",
        cursor: "pointer",
        display: "flex",
        fontSize: "9px",
        fontWeight: "350",
        height: height,
        p: "10px",
        margin: "2px",
        width: width,
        "&:hover": {
          outline: `2px solid ${color}`
        }
      }}>
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-all",
          }}
        >
          {item.label}
        </Box>
    </Paper>
  )
}

export default observer(MarginaliaNote)